<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="6" v-for="item in cards" :key="item.title">
        <el-card class="box-card">
          <div slot="header" class="clearfix">{{ item.title }}</div>
          <div class="text item">
            <svg class="icon" aria-hidden="true">
              <use :xlink:href="item.icon" style="width: 100px"></use>
            </svg>
            <span class="text">{{ item.data }}</span>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <div id="myTimer"></div>
    {{ currentTime }}
    <!-- 为 ECharts 准备一个具备大小（宽高）的 DOM -->
    <div id="main"></div>
  </div>
</template>

<script>
import { count } from '@/api/dashboard'
import * as echarts from 'echarts'

export default {
  data() {
    return {
      currentTime: "",//实时时间
      cards: [
        { title: '馆藏图书/册', data: 100, icon: '#iconlend-record-pro' },
        { title: '在借图书/册', data: 100, icon: '#iconvisit' },
        { title: '在馆图书/册', data: 100, icon: '#iconbook-pro' },
        { title: '借阅总数/册', data: 1000, icon: '#iconbook' },
        { title: '馆内读者/位', data: 1000, icon: '#iconpopulation' }
      ]
    }
  },
  mounted() {
    this.circleTimer()
    count().then(({ data }) => {
      console.log(data);
      this.cards[0].data = data.bookCount
      this.cards[1].data = data.inBorrowCount
      this.cards[2].data = data.inLibraryCount
      this.cards[3].data = data.lendRecordCount
      this.cards[4].data = data.readerCount

      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById('main'))
      // 绘制图表
      myChart.setOption({
        title: {
          text: '统计'
        },
        tooltip: {
          trigger: 'axis'
          // axisPointer: {
          //   type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          // }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: this.cards.map(item => item.title),
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            type: 'bar',
            label: { show: true },
            barWidth: '25%',
            data: [
              {
                value: this.cards[0].data,
                itemStyle: { color: '#16afcc' }
              },
              {
                value: this.cards[1].data,
                itemStyle: { color: '#fa4343 ' }
              },
              {
                value: this.cards[2].data,
                itemStyle: { color: '#f59311 ' }
              },
              {
                value: this.cards[3].data,
                itemStyle: { color: '#6ca30f ' }
              },
              {
                value: this.cards[4].data,
                itemStyle: { color: '#0e72cc ' }
              }
            ]
          }
        ]
      })
      window.addEventListener('resize', () => {
        myChart.resize()
      })
    })
  },
  methods: {
    circleTimer() {
      this.getTimer()
      setInterval(() => {
        this.getTimer()
      }, 1000)
    },
    getTimer() {
      var d = new Date()
      var t = d.toLocaleString()
      this.currentTime = t
    }
  }
}
</script>

<style scoped>
.box-card {
  /* width: 30%; */
  margin-bottom: 25px;
}

.clearfix {
  text-align: center;
  font-size: 15px;
}

.text {
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  vertical-align: super;
}

#main {
  width: 100%;
  height: 450px;
  margin-top: 20px;
}

.icon {
  width: 50px;
  height: 50px;
  padding-top: 5px;
  padding-right: 10px;
}
</style>